import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import $ from "jquery";

import SubRouter from "./views/SubRouter.vue";
import Auth from "./views/Auth.vue";
import { AuthService } from "./services/auth";
import comumService from "./services/comum";
import { encontraTelaComPermissao } from "./helpers/permissions";

Vue.use(Router);

const BASE_TITLE = "TCS - MMI Mapas";
const TITLE_SEPARATOR = " | ";
const authService = new AuthService();

const router = new Router({
	mode: "history",

	routes: [
		{
			path: "/auth",
			name: "auth",
			meta: { noRedirect: true },
			component: Auth
		},
		{
			path: "/",
			name: "home",
			meta: {
				title: "Dashboard - Mapa",
				perms: ["DASHBOARD_MAPAS"]
			},
			component: () => import("./views/Dashboard.vue")
		},
		{
			path: "/dashboard",
			name: "dashboard",
			meta: {
				title: "Dashboard",
				perms: ["DASHBOARD_MAPAS_CARDS"]
			},
			component: () => import("./views/DashboardArcelorMittal.vue")
		},
		{
			path: "/sensoriamento",
			name: "sensoriamento",
			meta: {
				title: "Sensoriamento",
				perms: ["DASHBOARD_MAPAS_CARDS"]
			},
			component: () => import("./views/Sensoriamento.vue")
		},
		{
			path: "/maquinas",
			meta: {
				title: "Máquinas"
			},
			component: SubRouter,
			children: [
				{
					path: "",
					name: "maquinas",
					meta: {
						title: "Identificadores de máquinas",
						perms: ["GER_U_IDENTIFICADORES_MAQUINAS"]
					},
					component: () => import("./views/Maquinas/List.vue")
				}
			]
		},
		{
			path: "/historicoLocalizacao",
			meta: {
				title: "Histórico de Localização"
			},
			component: SubRouter,
			children: [
				{
					path: "",
					name: "historico",
					meta: {
						title: "Histórico de Localização",
						perms: ["RLT_HISTORICO_LOCALIZACAO"]
					},
					component: () => import("./views/Historico/List.vue")
				}
			]
		},
		{
			path: "/historicoChecklist",
			meta: {
				title: "Histórico de Checklist"
			},
			component: SubRouter,
			children: [
				{
					path: "",
					name: "checklist",
					meta: {
						title: "Histórico de Checklist",
						perms: ["GER_S_CHECKLIST"]
					},
					component: () => import("./views/Checklist.vue")
				}
			]
		},

		// ERROS
		{
			name: "403",
			path: "/forbidden",
			meta: {
				title: "403 - Permissão insuficiente",
				perms: [],
				noRedirect: true
			},
			component: () => import("./views/403.vue")
		},

		// 404 (precisa ser a última rota deste arquivo)
		{
			name: "404",
			path: "*",
			meta: {
				title: "404 - Página não encontrada",
				perms: [],
				noRedirect: true
			},
			component: () => import("./views/404.vue")
		}
	]
});

router.beforeEach(async (to, from, next) => {
	// Obtém os metadados da rota de destino
	const meta = to.meta || {};

	// Altera título da janela de acordo com a página acessada
	document.title = (to.meta.title ? to.meta.title + TITLE_SEPARATOR : "") + BASE_TITLE;

	// Traz as permissões inerentes ao usuário
	let userPermissions = [];
	if (localStorage.params)
		userPermissions = (JSON.parse(localStorage.params).grupoUsuario).split(",");

	// Controle de permissões de acesso
	const permissions = meta.perms || [];
	if (permissions.length == 0) {
		// Página pública
		next();
	} else if (!(await authService.isLoggedIn())) {
		// Não logado
		await authService.requireLogin();
	} else {
		let autorizado = false;
		for (const perm of permissions) {
			if (userPermissions.indexOf(perm) !== -1) {
				// Acesso autorizado
				autorizado = true;
				break;
			}
		}

		if (autorizado) {
			await comumService.buscaAliasPredisposto();
			return next();
		}

		// A ideia é procurar uma página que o usuário possua permissão de acesso.
		// Caso não possua nenhuma permissão, ele será redirecionado
		// para uma página padrão (403).
		const result = encontraTelaComPermissao(userPermissions, router.options.routes);
		router.push({ name: result }).catch(e => {
			if (e.type != 8) console.log(e);
		});
	}
});

// Tooltips
router.afterEach(() => {
	$(".tooltip[role=tooltip]").tooltip("hide");
	setTimeout(() => {
		const $tooltips = $('[data-toggle="tooltip"]');
		if (window.innerWidth <= 790)
			$tooltips.tooltip("disable");
		else
			$tooltips.tooltip("enable");
	});
});

// Loading
NProgress.configure({ trickleRate: 0.1, trickleSpeed: 800, showSpinner: false });

router.beforeEach((to, from, next) => {
	NProgress.start();
	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router;
